@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720)*/
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.bg {
  background-position: center center;
  background-size: cover;
}
.bg-map {
  background-image: url(./images/map.jpg);
}

.bg-gradient {
  background: rgb(10, 10, 10);
  background: linear-gradient(
    0deg,
    rgba(10, 10, 10, 0) 0%,
    rgba(10, 10, 10, 1) 24%
  );
}

body,
*,
h3,
h1,
p {
  //font-family: Apple Symbols, Helvetica Neue, Helvetica, Calibri !important;
  font-family: "Merriweather", serif !important;
}

p,
span {
  line-height: 1.8rem !important;
}

.cursor-fly {
  cursor: url(./images/fly-small.png), auto !important;
}

button.badge {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 75%;
}

.place-1 .bg-door {
  background-image: url(./images/door-1.jpg);
}
.place-2 .bg-door {
  background-image: url(./images/door-2.jpg);
}
.place-3 .bg-door {
  background-image: url(./images/door-3.jpg);
}
.place-4 .bg-door {
  background-image: url(./images/door-4.jpg);
}
.place-5 .bg-door {
  background-image: url(./images/door-5.jpg);
}
.place-6 .bg-door {
  background-image: url(./images/door-6.jpg);
}
.iconbadge.bg-place-icon {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}
.bg-welcome {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  background-image: url(./images/welcome.jpg);
}
.place-1 .bg-place-icon {
  background-image: url(./images/ear.jpg);
}
.place-2 .bg-place-icon {
  background-image: url(./images/eye.jpg);
}
.place-3 .bg-place-icon {
  background-image: url(./images/person.jpg);
}
.place-4 .bg-place-icon {
  background-image: url(./images/trouser.jpg);
}
.place-5 .bg-place-icon {
  background-image: url(./images/camera.jpg);
}
.place-6 .bg-place-icon {
  background-image: url(./images/weather.jpg);
}

button.place-1 {
  top: 40%;
  left: 55%;
  background-image: url(./images/melone.jpg);
}

button.place-2 {
  top: 15%;
  right: 22%;
  background-image: url(./images/shoe.jpg);
}

button.place-3 {
  top: 30%;
  left: 15%;
  background-image: url(./images/knife.jpg);
  background-size: 70%;
}
button.place-4 {
  top: 50%;
  right: 15%;
  background-image: url(./images/frame.jpg);
}
button.place-5 {
  bottom: 25%;
  left: 27%;
  background-image: url(./images/jacket.jpg);
}
button.place-6 {
  bottom: 15%;
  left: 53%;
  background-image: url(./images/computer.jpg);
  background-size: 70%;
}

.fs-fadein-slow {
  opacity: 0;
  animation: fadein-slow 1s ease-in-out 3s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.fs-fadein-fast {
  opacity: 0;
  animation: fadein-slow 1s ease-in-out 0s forwards;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes fadein-slow {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: auto;
  }
}

//
// hamburger menu
.fs-menu {
  padding: 0px;
}
.fs-menu > li {
  list-style: none;
  display: inline-block;
  margin: 1.1em 1.3em;
  font-size: 1.3em;
}
@media only screen and (max-width: 350px) {
  .fs-menu > li {
    font-size: 1em;
  }
}
.fs-menu > li > a {
  text-decoration: none;
  transition: 500ms all cubic-bezier(0.19, 1, 0.22, 1) 233ms;
}
.fs-menu .fs-icon-arrowleft {
  margin-right: 8px;
  transform: translateY(1px);
}

.fs-hamburger {
  position: absolute;
  z-index: 100;
  right: 2px;
  top: -11px;
  cursor: pointer;
  transform-origin: 20% 50%;
  opacity: 1;
  transform: scaleX(0);
  transition: 250ms opacity 200ms cubic-bezier(0.19, 1, 0.22, 1),
    250ms transform 200ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 44px;
  height: 44px;
}
.fs-hamburger div {
  background-color: #181715;
  transition: 500ms background cubic-bezier(0.18, 1, 0.21, 1),
    500ms margin-top cubic-bezier(0.18, 1, 0.21, 1),
    500ms margin-bottom cubic-bezier(0.19, 1, 0.22, 1),
    500ms transform cubic-bezier(0.19, 1, 0.22, 1) 83ms;
  height: 2px;
  width: 24px;
  left: 10px;
  position: absolute;
  transform-origin: 50% 50%;
}
.fs-hamburger div::after,
.fs-hamburger div::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.fs-hamburger .line1 {
  top: 17px;
}
.fs-hamburger .line1::after {
  transition: 670ms transform cubic-bezier(0.19, 1, 0.22, 1) 150ms,
    200ms opacity;
}
.fs-hamburger .line2 {
  bottom: 17px;
}
.fs-hamburger .line2::after {
  transition: 670ms transform cubic-bezier(0.19, 1, 0.22, 1) 233ms,
    200ms opacity;
}
.fs-hamburger:not(.open):hover div:after {
  transform: scaleX(1);
}

.fs-hamburger.show {
  opacity: 1;
  transform: none;
}

.fs-hamburger.open .line1 {
  transform: rotate(45deg);
  margin-top: 4px;
}

.fs-hamburger.open .line2 {
  transform: rotate(-45deg);
  margin-bottom: 4px;
}
